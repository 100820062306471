$(document).ready(function () {
    gsap.registerPlugin(ScrollTrigger);

    if ($('.blockreassurance_product .product_reassurence_item').length > 0) {
        gsap.from('.product_reassurence_item', {
            y: -100,
            opacity: 0,
            duration: 0.5,
            stagger: {
                each: 0.1
            },
            scrollTrigger: {
                trigger: '.product-additional-info',
                markers: false,
                scrub: false, //suivis
                start: '30px 60%',//30px à partir du haut de l'élément et 60% du bas de l'écran
                toggleActions: "restart none resum pause"
            }
        })
    }


    if ($('#product .product-detail-btn').length > 0) {
        var $btnDetailsProduct = $(".product-detail-btn");
        var $detailsTab = $("#product-tabs-details");
        $btnDetailsProduct.on('click', function () {
            gsap.to(window, {duration: 1, scrollTo: {y: $detailsTab, offsetY: 20}, ease: 'power2'});
        })
    }

    // animation menu mobile
    var $menuTrigger = $('#menu-icon');
    var $items = $('.top-menu > li');
    $menuTrigger.on('click', function () {
        gsap.from($items, {
            x: 100,
            opacity: 0,
            duration: 0.3,
            stagger: {
                each: 0.01
            }
        })
    })
    //newsletter
    var $newsletterInput = $(".js-newsletter-input"),
        $newsletterInfos = $(".js-news-letter-infos");
    $newsletterInfos.hide();
    $newsletterInput.on('focus', function () {
        $newsletterInfos.slideDown('500');
    })

    $newsletterInput.on('blur', function () {
        $newsletterInfos.slideUp('500');
    })

    animProducts();
    animItems('.epi-reassurence-block-footer');


})


function animProducts() {
    //  gsap.registerPlugin(ScrollTrigger);
    gsap.defaults({ease: "power3"});
    const itemSelector = ".js-product-miniature";
    gsap.set(itemSelector, {y: 100, opacity: 0});
    // gsap.set(itemSelector, { autoAlpha: 0, yPercent: 100 });
    ScrollTrigger.batch(itemSelector, {
        once: true,
        start: "top 80%",
        onEnter: function (batch) {
            return gsap.to(batch, {
                opacity: 1,
                y: 0,
                stagger: {
                    each: 0.15,
                    axis: "x"
                }
            });
        }
    });


}

function animItems(items) {
    gsap.from(items, {
        y: -100,
        opacity: 0,
        duration: 0.9,
        stagger: { // wrap advanced options in an object
            each: 0.1,
            ease: "power3.in",
        },
        scrollTrigger: {
            trigger: items,
            start: "50px 90%",
            toggleActions: "restart none resum pause"

        }

    });
}





